import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Posts({ data }) {
  return (
    <Layout>
      <SEO title="Posts" />
      <div className="md:m-auto md:w-3/4 lead">
        {data.posts.edges.map(({ node }, index) => (
          <div
            key={node.id}
            className="text-salmon py-4 border-solid border-b border-teal-800"
          >
            <h2 className="md:text-3xl">
              <Link className="hover:underline" to={node.frontmatter.path}>
                {node.frontmatter.title}
              </Link>
            </h2>
            <h3 className="md:text-xl opacity-75">
              By {node.frontmatter.author || "System"} on{" "}
              {node.frontmatter.date}
            </h3>
            <p className="text-teal-300">{node.frontmatter.description}</p>
            <div className="text-right">
              <Link className="hover:underline" to={node.frontmatter.path}>
                Keep reading
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query allPosts {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            description
            author
          }
        }
      }
    }
  }
`
